const data = [
  {
    title: "Basic Settings",
    tableOneHastitle: false,
    tableOne: [
      ["League Name", "AGBO Superhero League Season 2"],
      ["Number of Teams", "14"],
      ["Scoring Type", "Head to Head Points, Point Per Reception"],
      ["Format", "League Manager"],
      ["Make League Viewable to Public", ""],
    ],
  },
  {
    title: "Draft Settings",
    tableOneHastitle: false,
    tableOne: [
      ["Draft Type", "Snake"],
      ["Draft Date", "Sep 8, 2020 @ 1:00 PM PDT"],
      ["Seconds Per Pick", 60],
      ["Draft Order", "Manually Set by League Manager"],
      ["Allow Draft Pick Trading", "No"],
    ],
  },
  {
    title: "Roster",
    tableOneHastitle: false,
    tableOne: [
      ["Roster Size", "12"],
      ["Total Starters", "7"],
      ["Total on Bench", "5 (4 IR)"],
    ],
    tableTwoHastitle: true,
    tableTwo: [
      ["POSITION", "STARTERS", "MAXIMUMS"],
      ["Quarterback (QB)", 1, 4],
      ["Team Quarterback (TQB)", 0, "N/A"],
      ["Running Back (RB)", 2, 10],
      ["Running Back/Wide Receiver (RB/WR)", 0, "N/A"],
      ["Wide Receiver (WR)", 2, 10],
      ["Wide Receiver/Tight End (WR/TE)", 0, "N/A"],
      ["Tight End (TE)", 1, "4"],
      ["Flex (FLEX)", 1, "N/A"],
      ["Offensive Player Utility (OP)", 0, "N/A"],
      ["Defensive Tackle (DT)", 0, "N/A"],
      ["Defensive End (DE)", 0, "N/A"],
      ["Linebacker (LB)", 0, "N/A"],
      ["Edge Rusher (EDR)", 0, "N/A"],
      ["Defensive Line (DL)", 0, "N/A"],
      ["Cornerback (CB)", 0, "N/A"],
      ["Safety (S)", 0, "N/A"],
      ["Defensive Back (DB)", 0, "N/A"],
      ["Defensive Player Utility (DP)", 0, "N/A"],
      ["Team Defense/Special Teams (D/ST)", 0, "N/A"],
      ["Place Kicker (K)", 0, "N/A"],
      ["Punter (P)", 0, "No Limit"],
      ["Head Coach (HC)", 0, "No Limit"],
      ["Bench (BE)", 5, "N/A"],
      ["Injured Reserve (IR)", 4, "N/A"],
    ],
  },
  {
    title: "Scoring",
    tableOneHastitle: true,
    tableOne: [
      ["Passing", "pts"],
      ["Every 25 passing yards (PY25)", "0.04"],
      ["TD Pass (PTD)", "4"],
      ["Interceptions Thrown (INT)", "-1"],
      ["2pt Passing Conversion (2PC)", "2"],
      ["400+ yard passing game (P400)", "1"],
    ],
    tableTwoHastitle: true,
    tableTwo: [
      ["Rushing", "pts"],
      ["Every 10 rushing yards (RY10)", "0.1"],
      ["TD Rush (RTD)", "6"],
      ["2pt Rushing Conversion (2PR)", "2"],
      ["100-199 yard rushing game (RY100)", "1"],
      ["200+ yard rushing game (RY200)", "2"],
    ],
    tableThreeHastitle: true,
    tableThree: [
      ["Receiving", "pts"],
      ["Every 10 receiving yards (REY10)", "0.1"],
      ["Each reception (REC)", "1"],
      ["TD Reception (RETD)", "6"],
      ["2pt Receiving Conversion (2PRE)", "2"],
      ["100-199 yard receiving game (REY100)", "1"],
      ["200+ yard receiving game (REY200)", "2"],
    ],
    tableFourHastitle: true,
    tableFour: [
      ["Team Defense / Special Teams", "pts"],
      ["Kickoff Return TD (KRTD)", "0"],
      ["Punt Return TD (PRTD)", "0"],
    ],
    tableFiveHastitle: true,
    tableFive: [
      ["Miscellaneous", "pts"],
      ["Kickoff Return TD (KRTD)", "6"],
      ["Punt Return TD (PRTD)", "6"],
      ["Fumble Recovered for TD (FTD)", "6"],
      ["Total Fumbles Lost (FUML)", "-2"],
    ],
  },
  {
    title: "Teams And Divisions Settings",
    tableOneHastitle: true,
    tableOne: [
      ["UNIVERSE"],
      ["Team Reynolds"],
      ["Team Hemsworth"],
      ["Team Downey"],
      ["Team Berry"],
      ["Team Olsen"],
      ["Team Pratt"],
      ["Team Russo"],
      ["Team Liu"],
      ["Team Evans"],
      ["Team Holland"],
      ["Team Klementieff"],
      ["TeamMackie"],
      ["Team Rudd"],
      ["Team Gillan"],
    ],
    tableProps: { centered: true },
  },
  {
    title: "Player Rules",
    tableOneHastitle: false,
    tableOne: [
      ["Cant cut list", "Yes"],
      ["Player Universe", "NFL"],
    ],
  },
  {
    title: "Acquisition And Waiver Rules",
    tableOneHastitle: false,
    tableOne: [
      ["Lineup Changes", "Lock individually at Scheduled Gametime"],
      ["Player Acquisition System", "Free Agent Budget"],
      ["Season Acquisition Limit", "No Limit"],
      ["Waiver Period", "1 Day"],
      ["Player Acquisition Budget", "100"],
      ["Minimum Offer", "0"],
      [
        "Free Agent Budget Tiebreaker",
        "Reset Each Week to Inverse Order of Standings",
      ],
    ],
  },
  {
    title: "Trade Rules",
    tableOneHastitle: false,
    tableOne: [
      ["Trade Limit", "No Limit"],
      ["Trade Deadline", "Nov 16, 2020 @ 12:00 PM PST"],
      ["Trade Review Period", "1 Day"],
      ["Votes Required to Veto Trade", "4"],
    ],
  },
  {
    title: "Keepers Rules",
    tableOneHastitle: false,
    tableOne: [
      ["Use Keepers for 2020 Season", "No"],
      ["Use Keepers for 2021 Season", "No"],
    ],
  },
  {
    title: "Regular Season Setup",
    tableOneHastitle: false,
    tableOne: [
      ["Start of Regular Season", "Week 1 (Start of Season)"],
      ["Weeks Per Matchup", "1"],
      ["Regular Season Matchups", "13"],
      ["Matchup Tie Breaker", "No tie breakers"],
      ["Home Field Advantage", "None"],
    ],
  },
  {
    title: "Playoff Bracket Setup",
    tableOneHastitle: false,
    tableOne: [
      ["Playoff Teams", "8"],
      ["Weeks Per Playoff Matchup", "1"],
      ["Playoff Seeding Tie Breaker", "Total Points For"],
      ["Playoff Home Field Advantage", "None"],
    ],
  },
]

export default data
