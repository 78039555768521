import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import guillermo from "./../images/cards/new_guillermo.jpg"

import Subnavigation from "./../components/sub-navigation"
import HeaderSmall from "./../components/header-small"

import sections from "./../data/rules"

const pageTitle = "League Rules, Settings & Schedule"

const RenderTable = ({ table, name = "", centered = false, headers }) => (
  <div className="mb-8 max-w-md mx-auto">
    <table
      className="table-auto m-auto"
      style={{ textAlign: centered ? "center" : "left" }}
    >
      <tbody>
        {table &&
          table.map((row, rowIndex) => (
            <tr key={`${name}-row-${rowIndex}`}>
              {row &&
                row.map((field, fieldIndex) => (
                  <>
                    {headers && rowIndex === 0 ? (
                      <td
                        key={`${name}-${fieldIndex}-${field}`}
                        className="text-sm md:text-md border px-4 py-2 fm-russo"
                      >
                        {field}
                      </td>
                    ) : (
                      <td
                        key={`${name}-${fieldIndex}-${field}`}
                        className="text-sm md:text-lg border px-4 py-2 fm-oswald"
                      >
                        {field}
                      </td>
                    )}
                  </>
                ))}
            </tr>
          ))}
      </tbody>
    </table>
  </div>
)

const IndexPage = () => {
  return (
    <Layout header={false}>
      <SEO title={pageTitle} />

      <Subnavigation />

      <article className="pt-24">
        <HeaderSmall />

        <h1 className="text-4xl md:text-6xl text-center mt-8 md:mb-16 px-6">
          Rules and Settings
        </h1>

        <div className="pt-4 pb-8">
          <div className="w-full md:w-1/3 lg:w-1/4 p-2 md:p-2 lg:p-2 xl:p-4 mb-2 lg:mb-8 mx-auto">
            <img
              src={guillermo}
              alt="Guillermo Lozano"
              className="mx-auto"
              style={{ maxWidth: 320 }}
            />

            {
              <p className="fm-russo text-center text-xl lg:text-xl xl:text-2xl px-1 leading-6 pt-4">
                COMMISSIONER LOZANO
              </p>
            }
          </div>
        </div>

        {sections.map(
          (
            {
              title,
              tableOne,
              tableTwo,
              tableThree,
              tableFour,
              tableFive,
              tableProps,
              ...props
            },
            index
          ) => (
            <div className="mb-2 md:mb-2 p-4">
              <h2 className="text-lg md:text-2xl text-center mb-8">{title}</h2>
              {tableOne && (
                <RenderTable
                  table={tableOne}
                  name={`table-${index}-tableOne-${title}`}
                  centered={tableProps?.centered}
                  headers={props.tableOneHastitle}
                />
              )}
              {tableTwo && (
                <RenderTable
                  table={tableTwo}
                  name={`table-${index}-tableTwo-${title}`}
                  headers={props.tableTwoHastitle}
                />
              )}
              {tableThree && (
                <RenderTable
                  table={tableThree}
                  name={`table-${index}-tableThree-${title}`}
                  headers={props.tableThreeHastitle}
                />
              )}
              {tableFour && (
                <RenderTable
                  table={tableFour}
                  name={`table-${index}-tableFour-${title}`}
                  headers={props.tableFourHastitle}
                />
              )}
              {tableFive && (
                <RenderTable
                  table={tableFive}
                  name={`table-${index}-tableFive-${title}`}
                  headers={props.tableFiveHastitle}
                />
              )}
            </div>
          )
        )}
      </article>
    </Layout>
  )
}

export default IndexPage
